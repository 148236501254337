import { destinationImages } from "../utils/constants";

const Destination=()=>{
    
    return(
        <div className="sm:h-[96vh] sm:pl-10  pt-[4%]">
            <div className="flex flex-row pl-5">
                <div className="destination">
                    <div className="inline-block">
                        <h1 className="abyssinica-sil-regular text-3xl mb-3">Popular Destinations</h1>
                        <hr className="border-[#8DCD80] border-[1.8px] mb-4 ml-[2px] mr-[17px]" />
                    </div>
                </div>
                <div className="sm:flex flex-row space-x-8 ml-[70%] hidden">
                    <img src ="left-arrow.svg" alt="left-arrow" className="cursor-pointer h-[40px] " onClick={()=>{document.querySelector(".destination-container").scrollBy({left:-400,behavior:"smooth"})}} />
                    <img src="right-arrow.svg"  alt="right-arrow" className="cursor-pointer h-[40px]" onClick={()=>{document.querySelector(".destination-container").scrollBy({left:400,behavior:"smooth"})}}/>
                </div>
            </div>
            <p className="text-[#6A6E71] mb-3 pl-5">Most popular destinations around the world, from historical places to natural wonders.</p>
            
            <div className="flex flex-col sm:flex-row items-center w-full overflow-x-auto destination-container">
                {destinationImages.map((dest,idx)=>
                <div className="realtive w-[80vw] sm:h-[70vh] h-[470px] object-cover rounded-md sm:w-[27vw] p-3 sm:ml-[2%] sm:my-0 my-4 flex-shrink-0 flex flex-col justify-end mx-auto" style={{
                    backgroundImage:`url(${dest.imageUrl})`,
                    backgroundSize:"cover"
                }}>
                    {/* <img src={dest.imageUrl} alt={dest.label} className="w-full" key={idx} /> */}
                    <h2 className=" text-3xl text-white roboto-serif-header ml-3 mb-3" style={{fontSize:"35px"}}>{dest.label}</h2>
                    <a href="https://maps.app.goo.gl/y9MPDX2EacPLcvHTA" target="_blank_" className="mb-[4vh]">
                        <div className="flex items-center">
                            <img src="location-icon.png" alt="location-icon" className="w-[44px] h-[28px] object-contain"/>
                            <h1 className="text-3xl text-white">Dandeli, Karnataka</h1>
                        </div>
                    </a>
                </div>
                 
                )}
            </div>
            
            
        </div>
    )
}

export default Destination;