import { useParams } from "react-router-dom";
import {manomayaCottages,jungleVibes,riverPoint, dewDrops, kings, riverEdge, packageDetails, sendMessage} from "../utils/constants";
import { useEffect, useState } from "react";
import { FaArrowLeft,FaArrowRight } from "react-icons/fa";


const ResortPackage=()=>{
    const {packaged}=useParams();
    const newPackage= packaged.split("-").length>1? packaged.split("-")[0]+packaged.split("-")[1][0].toUpperCase()+packaged.split("-")[1].slice(1) : packaged;    
    console.log(newPackage);
    const [packageData,setPackageData]=useState(dewDrops);
    const [automate,setAutomate]=useState(true);
    const [packageName,setPackageName]=useState("");
    const [currentPackage,setCurrentPackage]=useState("");

    useEffect(()=>{

        switch (newPackage) {
            case 'jungleVibes':
                setPackageData(jungleVibes);
                setPackageName("Jungle Stay");
                setCurrentPackage(packageDetails[0]);
                break;
            case 'riverPoint':
                setPackageData(riverPoint);
                setPackageName("River view Stay");
                setCurrentPackage(packageDetails[1]);
                break;
            case 'dewDrops':
                setPackageData(dewDrops);
                setPackageName("Luxury Nature View Stay");
                setCurrentPackage(packageDetails[4]);
                break;
            case 'kings':
                setPackageData(kings);
                setPackageName("Luxury Pool View Stay");
                setCurrentPackage(packageDetails[3]);
                break;
            case 'manomayaCottages':
                setPackageData(manomayaCottages);
                setPackageName("A Frame Luxury Cottages");
                setCurrentPackage(packageDetails[5]);
                break;
            case 'riverEdge':
                setPackageData(riverEdge);
                setPackageName("River Side Stay");
                setCurrentPackage(packageDetails[2]);
                break;
            default:
                setPackageData([]); 
                setPackageName("");
                currentPackage("");
                break;
        }
        //eslint-disable-next-line
    },[newPackage]);
    const mainImage = packageData[0] ? packageData[0].imageUrl : '';
    console.log(mainImage);

    useEffect(() => {
        if(automate){
            const containers = document.querySelectorAll(".new-package-container");
            const isMobile = window.innerWidth <= 768;
                let scrollStep;
                let scrollInterval;
                if(isMobile){
                    scrollStep = 10; 
                    scrollInterval = 70; 

                }
                else{
                    scrollStep = 180; 
                    scrollInterval = 1000; 
                }
            // const scrollStep = 180; 
            // const scrollInterval = 1000;  // 2sec -2000 milliseconds //380 with 2000 is also good
        
            // Function to scroll each container
            const scrollAllContainers = () => {
              containers.forEach(container => {
                const maxScroll = container.scrollWidth - container.clientWidth;
                const currentScroll = container.scrollLeft;
        
                if (currentScroll + container.clientWidth >= maxScroll) {
                  // Scroll back to the start when reaching the end
                  container.scrollTo({ left: 0, behavior:  `${ isMobile ?'auto':'smooth'}` });
                } else {
                  // Scroll by the specified step amount
                  container.scrollBy({ left: scrollStep, behavior: "smooth" });
                }
              });
            };
        
            // Set interval for continuous scrolling
            const intervalId = setInterval(scrollAllContainers, scrollInterval);
        
            // Cleanup the interval on component unmount
            return () => clearInterval(intervalId);
        }
       
      }, [automate]);
    
    return(
        <>
            {packageData?.length>0 && <img src={`${packageData[0]?.imageUrl}`} alt={`${packageData[0]?.label}`} className="w-[100vw] object-cover h-[80vh]"
            style={{opacity:0.91}} />}
            <div className="w-[100vw] select-none">
                <div className="flex flex-row items-center">
                    <FaArrowLeft onClick={()=>{setAutomate(false);document.querySelector(".new-package-container").scrollBy({left:-400,behavior:"smooth"})}}
                            className="hidden sm:block"
                            style={{
                                height:"60px",
                                width:"60px",
                                color:"black",
                                cursor:"pointer",
                                marginLeft:"auto"
                            }}
                            />
                    <div className="flex sm:flex-row w-[72%] mx-auto package-container overflow-x-auto new-package-container items-center">
                        
                        {packageData.length>0 && packageData?.slice(2).map((packag, idx) => (
                            <div
                                className="w-[92vw] sm:w-[24vw] flex-shrink-0 sm:mr-5 sm:p-4 p-1 h-[38vh] overflow-y-hidden" // for gap change paddings
                                key={idx}
                            >
                                <img
                                    src={packag?.imageUrl}
                                    alt={packag?.label}
                                    className="w-full object-cover h-[90%]"
                                />
                            </div>
                        ))}
                    
                    </div>
                    <FaArrowRight onClick={()=>{setAutomate(false);document.querySelector(".new-package-container").scrollBy({left:400,behavior:"smooth"})}} className="hidden sm:block" 
                         style={{
                            height:"60px",
                            width:"60px",
                            color:"black",
                            cursor:"pointer",
                            marginRight:"auto"
                        }}
                        />
                </div>
                <div className="flex items-center sm:px-[10%] py-[4%] sm:flex-row flex-col-reverse">
                    <div className="sm:w-[50%] w-[98vw] p-3 sm:p-4">
                        <h1 className="text-2xl font-bold">Room Overview</h1>
                        <p className="text-lg sm:text-justify text-left">{currentPackage.desc} </p>
                        {/* <a href={`https://wa.me/'${contactOwner.number}'/?text=Hello,+we+are+planning+a+trip+to+dandeli`} target="_blank_"> */}
                        <button className="bg-[#1B9B00] text-white rounded-[24px] pl-7 pr-7 py-2 flex flex-row items-center mt-[4vh]" onClick={()=>{sendMessage()}}>Book Now</ button>
                    </div>
                    <img src={`${packageData[1].imageUrl}`} alt="" className="sm:w-[50%] w-[85%] mx-auto sm:mx-0" />
                </div>
                <div className="flex flex-center px-[1%] sm:px-[10%] py-[4%] sm:flex-row flex-col">
                    <img src={`${packageData[2].imageUrl}`} alt="" className="sm:w-[50%] w-[90%] mx-auto sm:mx-0" />
                    <div className="sm:w-[50%] sm:pl-[5%] w-[98vw] pl-3">
                        <h1 className="text-2xl font-bold sm:mt-1 mt-2">Package Inclusions</h1>
                        <ul className="list-disc list-outside text-lg mb-3 p-2 pl-4 sm:pl-2">
                            <li>One Night Adventure Package</li>
                            <li>Package includes 1 Breakfast, 1 Lunch, 1 Dinner</li>
                            <li>Check-In: 12:00 PM | Check-Out: 11:00 AM</li>
                            <li>12 Activities, 5 Sightseeing Tours included</li>
                            <li>Complimentary Welcome Drink on Arrival</li>
                            <li>Transportation available upon request</li>
                        </ul>

                        <h1 className="text-2xl font-bold">Note</h1>

                        <ul className="list-disc list-outside text-lg p-2 pl-4 sm:pl-2">
                            <li>Washrooms will be attached for Luxury Cottages</li>
                            <li>Kids under 5 stay free</li>
                            <li>Water activities are for ages 15 and above</li>
                            <li>Rafting schedule is subject to river conditions</li>
                            <li>Pets are not allowed in the accommodation areas</li>
                            <li>Vegetarian and non-vegetarian meal options are available</li>
                        </ul>

                    </div>
                </div>
            </div>

            <h1 className="absolute top-[35vh] text-6xl sm:text-8xl text-white font-semibold sm:font-extrabold text-center"
            style={{left: "50%",
                transform: "translateX(-50%)",
                width: "auto",
                }}
            >{packageName.toUpperCase()}</h1>
            
        </>
    )
}

export default ResortPackage;