import { useEffect, useState } from "react";
import { IoMenu } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { contactOwner, sendMessage } from "../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";

const Navbar=()=>{
    const [isMobileViewOpen,setIsMobileViewOpen]=useState(false);
    const location = useLocation();
    const navigate=useNavigate();
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        let offset=0;
        if (element) {
            const viewportHeight = window.innerHeight;
            if(id!=="home" && id!=="resorts"){
                offset = viewportHeight * 0.10;
            }
           
          window.scrollTo({
            top: element.offsetTop -offset,
            behavior: 'smooth',
          });
        }
      };

      useEffect(() => {
        if (location.state && location.state.scrollTo) {
          scrollToSection(location.state.scrollTo);
        }
      }, [location]);

    return(
        <div className="h-[10vh] bg-white flex flex-row items-center fixed w-[100vw]" style={{zIndex:9999}}>
            <img src="dandeli-new-logo.png" alt="" className="ml-3 sm:ml-[5%] w-[20%] h-[70%] object-contain" style={{width:"140px",height:"125px"}} />
            <div className="sm:flex flex-row hidden w-[45vw] justify-evenly items-center text-[#1D1E20]">
                <a href="/#home" className="">
                    <p className="">Home</p>
                </a>
                <a href="/#resorts" className="">
                    <p>Resorts</p>
                </a>
                <a href="/#activities" className="">
                    <p>Activities</p>
                </a>
                <a href="/#about" className="">
                    <p>About Us</p>
                </a>
                <a href="/#refund-policy" className="">
                    <p>Refund Policy</p>
                </a>
            </div>
            <div className="flex flex-row sm:w-[20vw] items-center ml-auto mr-3 sm:mr-8">
                <a href={`tel:+${contactOwner.number}`}><div className="rounded-[23px] border border-[#CDD3DB] border-1 px-3 py-1 mr-4 text-[#31373D] hidden sm:block">Call Us</div></a>
                {/* <a href={`https://wa.me/'${contactOwner.number}'/?text=Hello,+we+are+planning+a+trip+to+dandeli`} target="_blank_"> */}
                <div className="border-none text-white rounded-full sm:rounded-[23px] bg-[#1BA100] flex flex-start items-center px-2 py-2 sm:px-4 sm:py-2 cursor-pointer" onClick={()=>{sendMessage()}}> <p className="hidden sm:block">Chat with Us</p> <img src="whatsapp-ico.png" alt="whatsapp" className="ml-3"/></div>
                {!isMobileViewOpen ?< IoMenu className="sm:hidden " onClick={()=>{setIsMobileViewOpen(true)}}  style={{
                    width: '28px',
                    height: '25px',
                    objectFit: 'contain',
                }} />:<RxCross2 onClick={()=>{setIsMobileViewOpen(false)}}  style={{
                    width: '25px',
                    height: '28px',
                    marginLeft:'3px',
                    objectFit: 'contain',
                }} />}
            </div>
            {isMobileViewOpen&& <div className="fixed top-20 right-0 z-1000 flex flex-col w-3/4 h-screen p-3 bg-white shadow-lg md:w-1/2 animate-slide-in text-black">
            <div className="py-1 border-b" onClick={(e) => {
                    e.preventDefault();
                    if (location.pathname !== '/') {
                        navigate('/', { state: { scrollTo: "home" } });
                      } else {
                        scrollToSection("home");
                      }
                    // scrollToSection('home');
                    setIsMobileViewOpen(false);
                }}>
                    <p className="">Home</p>
                </div>
                <a href="/#resorts" className="py-1 border-b" onClick={(e) => {
                    
                    e.preventDefault();
                    if (location.pathname !== '/') {
                        navigate('/', { state: { scrollTo: "resorts" } });
                      } else {
                        scrollToSection("resorts");
                      }
                    // scrollToSection('resorts');
                    setIsMobileViewOpen(false);
                }}>
                    <p>Resorts</p>
                </a>
                
                {/* <a href="/#privacy-policy" className="py-1 border-b" onClick={()=>{setIsMobileViewOpen(false)}}>
                    <p>Privacy Policy</p>
                </a> */}
                <a href="/#activities" className="py-1 border-b" onClick={(e) => {
                    e.preventDefault();
                    if (location.pathname !== '/') {
                        navigate('/', { state: { scrollTo: "activities" } });
                      } else {
                        scrollToSection("activities");
                      }
                    // scrollToSection('activities');
                    setIsMobileViewOpen(false);
                }}>
                    <p>Activities</p>
                </a>
                <div className="py-1 border-b" onClick={(e) => {
                    e.preventDefault();
                    if (location.pathname !== '/') {
                        navigate('/', { state: { scrollTo: "about" } });
                      } else {
                        scrollToSection("about");
                      }
                    // scrollToSection('about');
                    setIsMobileViewOpen(false);
                }}>
                    <p>About Us</p>
                </div>
                <a href="/#refund-policy" className="py-1 border-b"  onClick={(e) => {
                    e.preventDefault();
                    if (location.pathname !== '/') {
                        navigate('/', { state: { scrollTo: "refund-policy" } });
                      } else {
                        scrollToSection("refund-policy");
                      }
                    // scrollToSection('refund-policy');
                    setIsMobileViewOpen(false);
                }}>
                    <p>Refund Policy</p>
                </a>
            </div>}

        </div>

    )
}

export default Navbar;