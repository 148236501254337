import { activities } from "../utils/constants";

const Activities=()=>{
    
    return(
        <div className="sm:h-[106vh] sm:pl-10 pl-5 sm:pt-[6vh] bg-[#030922] text-white relative -z-1 pt-3" id="activities">
            <div className="flex flex-row">
                <div className="destination inline-block">
                    <h1 className="abyssinica-sil-regular text-3xl mb-3 font-semibold">Activities</h1>
                    <hr className="border-[#8DCD80] border-[1.4px] mb-4 mr-[12px] ml-[2px]" />
                </div>
                <div className="sm:flex flex-row space-x-8 ml-[72%] hidden">
                    <img src ="left-arrow.svg" alt="left-arrow" className="cursor-pointer  h-[40px]" onClick={()=>{document.querySelector(".activity-container").scrollBy({left:-400,behavior:"smooth"})}} />
                    <img src="right-arrow.svg"  alt="right-arrow" className="cursor-pointer  h-[40px]" onClick={()=>{document.querySelector(".activity-container").scrollBy({left:400,behavior:"smooth"})}} />
                </div>
            </div>
            
            <p className="">20 years from now you will be more disappointed by the things that you didn’t do. Stop regretting and start travelling, start throwing off the bowlines.</p>
            <div className=" flex flex-col flex-wrap h-[92%] w-[78vw] mx-auto mt-[2%] activity-container overflow-x-auto">
                {activities.map((activity,idx)=>
                    <div className="flex flex-col items-start flex-shrink-0 mx-3 mb-2" key={idx}>
                        <img src={activity.imageUrl} alt={activity.label} className={` max-h-[38vh] sm:max-h-[38vh] max-w-[95%]  sm:max-w-[90%]`}/>
                        <p className={`text-lg mt-[4px] ${activity.imageUrl==="2zorbing-1.JPG"?'sm:mb-5':''}`}>{activity.label}</p>
                    </div>
                )}
            </div>
            <img src="activities-leaf.svg" className="absolute top-5 right-9 -z-1 pointer-events-none" alt="tropical-leaf" />

        </div>
    )
}

export default Activities;