import { contactOwner, sendMessage } from "../utils/constants";

const Booknow=()=>{
    
    return( 
        <div className="h-[94vh] relative hidden sm:block">
            <div className="pt-[5vh]">
                
                <img src="book-bg.png" alt="" className="absolute w-[50vw] h-[85vh] ml-[35vw]" />
                <img src="mountains.png" alt="mountains" className="absolute w-[37vw] h-[57vh] z-4 top-[20vh] left-[15vw]" />
                <img src="enquire.png" alt="enquire" className="absolute -top-2 left-[32vw]"/>
                <div className="ml-[60vw] absolute top-[34vh]">
                    <h1 className="text-2xl font-medium mb-6">Start planning your<br/>
                    tailor-made holiday</h1>
                    <p className="mb-3">Speak to one of our travel specialists</p>
                    <div className="flex flex-col">
                    {/* <a href={`https://wa.me/'${contactOwner.number}'/?text=Hello,+we+are+planning+a+trip+to+dandeli`} target="_blank_"> */}
                        <button className="bg-[#1B9B00] rounded-[32px] mb-3 text-white py-2 px-4 w-[72%]" onClick={()=>{sendMessage()}}>Book Now</button>
                        {/* </a> */}
                        <a href={`tel:+${contactOwner.number}`}>
                            <button className="text-#1B9B00 rounded-[32px] border border-[#CDD3DB] mb-3 text-[#1B9B00]  py-2 px-4 w-[72%]">Call Now</button>
                        </a>
                    </div>
                    
                    <div className="flex flex-row items-center space-x-2">
                        {/* <img src="two-profiles.svg" alt="" className=""/> */}
                        <div className="h-[10px] w-[10px] bg-[#45EF09] rounded-full" ></div>
                        <p className="sm:pl-1">AVAILABLE NOW</p>
                    </div>
                </div>
                
            </div>
            
            
        </div>
    )
}

export default Booknow;