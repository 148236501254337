import { availablePages, contactOwner, sendMessage, SocialContacts } from "../utils/constants";
import "../index.css";

const Footer=()=>{
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        let offset=0;
        if (element) {
            const viewportHeight = window.innerHeight;
            if(id!=="home" && id!=="resorts"){
                offset = viewportHeight * 0.10;
            }
           
          window.scrollTo({
            top: element.offsetTop -offset,
            behavior: 'smooth',
          });
        }
      };
    
    return(
        <div className='bg-[#051F00] pl-[5%] sm:pl-0 sm:h-[65vh] w-full flex flex-col sm:flex-row pt-[18vh] justify-evenly text-white footer relative'>
            
            <div className="">
                <img src="dandeli-new-logo1.png" alt="logo" className="w-[72%] h-[50%] sm:h-[40%] sm:w-[70%] object-contain m-2" />
                <p className="mt-[17vh] hidden sm:block">Copyright © DandeliTripZone 2024 All rights reserved</p>

            </div>
            
            <div className="mt-[4] sm:mt-0 hidden sm:block">
                <h1 className="flex flex-col text-2xl font-semibold sm:mb-4 mt-3 sm:mt-0 mb-1">Menu</h1>
                {availablePages.map((page,idx)=>
                <a href={`${page.navigateTo}`} key={idx}>
                    <p className="text-sm mb-1" >
                     {page.name}
                    </p>
                </a>)}
            </div>
            <div className="mt-[4] sm:mt-0 sm:hidden block">
                <h1 className="flex flex-col text-2xl font-semibold sm:mb-4 mt-3 sm:mt-0 mb-1">Menu</h1>
                {availablePages.map((page,idx)=>
                <div onClick={()=>{scrollToSection(page.navigateTo.slice(2))}} key={idx}>
                    <p className="text-sm mb-1" >
                     {page.name}
                    </p>
                </div>)}
            </div>

            <div className="flex flex-col mt-[4] sm:mt-0 gap-1">
                <h1 className="text-2xl font-semibold sm:mb-4 mt-3 sm:mt-0 mb-1">Information</h1>
                <p className="text-sm">Supports</p>
                <p className="text-sm">Terms&Conditions</p>
                <p className="text-sm">Refund Policy</p>
            </div>
            
            <div className="flex flex-col mt-[4] sm:mt-0 gap-1">
                <h1 className="text-2xl font-semibold sm:mb-4 mt-3 sm:mt-0 mb-1">Contact Info</h1>
                <p className="text-sm">{contactOwner.email}</p>
                <p className="text-sm">+91 {contactOwner.number.slice(2)}</p>
                <p className="text-sm">+91 {contactOwner.number2.slice(2)}</p>
                <p className="text-sm">NWKRTC Bus Stand Dandeli, Karnataka.</p>
            </div>
            
            <div className="flex flex-col pb-4 sm:pb-0 mt-[4] sm:mt-0">
                <h1 className="text-2xl font-semibold sm:mb-4 mt-3 sm:mt-0 mb-1">Follow us on</h1>
                <div className="flex flex-row items-center">
                    {SocialContacts.map((media,idx)=>
                    <a href={media.url} key={idx} target="_blank_" className={`${media.label!=="twitter" ?'mr-3':''}`}>
                        <img src={media.image} alt={media.label} className=""/>
                    </a>)}
                    <button onClick={()=>{sendMessage()}} className="m-0">
                        <img src="whatsapp-i2.png"  alt="whatsapp" height={47} width={47} className=""/>
                    </button>
                </div>
            </div>
            <p className="sm:mt-[17vh] mt-[3vh] block sm:hidden">Copyright © DandeliTripZone 2024 <br/> All rights reserved</p>
            <img className="h-[131px] w-[158px] top-0 left-0 absolute" src="footer-leaf-1.svg" alt="leaf-1" />
            <img className="h-[151px] w-[158px] right-12 bottom-10 absolute" src="footer-leaf-2.svg" alt="leaf-2" />
        </div>
    )
}

export default Footer;