import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
// import About from './pages/about';
// import Activities from './pages/activities';
// import Booknow from './pages/booknow';
// import Destination from './pages/destinations';
import Footer from './pages/footer';
// import Home from './pages/home';
import Navbar from './pages/navbar';
// import Packages from './pages/packages';
// import Review from './pages/review';
import AllPages from './pages/allpages';
import ResortPackage from './pages/resortpackage';
import { contactOwner } from './utils/constants';


function App() {
  return (
    <div className="App overflow-x-hidden w-[100vw]">
      <BrowserRouter>
        <Navbar/>
          <Routes>
              <Route exact path="/" element={<AllPages/>}/>
              <Route exact path="/:packaged" element={<ResortPackage/>}/>
          </Routes>
          <a href={`tel:+${contactOwner.number}`}>
          <img className='fixed bottom-[9vh] right-[8vw] h-[48px] w-[48px] sm:hidden block' style={{transform: 'rotate(-17deg)'}} src="calli1.png" alt="call-icon" />
          </a>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
