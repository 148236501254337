export const SocialContacts=[
    {label:"facebook",image:"facebook.svg",url:"https://www.facebook.com/people/Dandeli-trip-zone/61562767486474/"},
    // {label:"pinterest",image:"pinterest.svg",url:""},
    {label:"instagram",image:"instagram.svg",url:"https://www.instagram.com/dandeli_tripzone_/"},
    {label:"twitter",image:"twitter.svg",url:""},
    // {label:"whatsapp",image:"whatsapp-i2.png",url:"",height:"",width:""},
]
export const availablePages=[
    {name:"Home",navigateTo:"/#home"},
    {name:"Resorts",navigateTo:"/#resorts"},
    {name:"Activities",navigateTo:"/#activities"},
    {name:"About Us",navigateTo:"/#about"},
    {name:"Refund Policy",navigateTo:"/#resorts"},
]
export const reviews=[
    {text:"Dandeli Trip Zone is amazing! Jungle Vibes was cozy, and the Jungle Safari was a thrill.Rafting and kayaking were the highlights. A perfect mix of adventure and relaxation! Can't wait to visit again!",
    stars:"rating-5.svg",name:"Abhishek",profession:"Developer"},
    {text:"Our family loved Dandeli Trip Zone! Croc Park was fun, and Manomaya Cottages offered luxury.Friendly staff and memorable experiences. Can't wait to return! Can't wait to return for more adventures!",
    stars:"rating-5.svg",name:"Charan",profession:"Accounts"},
    {text:"River Point was a serene escape. Moulangi Eco Park's beauty was breathtaking, and zorbing was a blast.The peaceful surroundings were perfect for unwinding.Highly recommended for nature lovers!",
    stars:"rating-4.svg",name:"Praveen",profession:"Software Engineer"},
    {text:"River Edge was perfect for adventure. Rafting, zip lining, and river swimming were exhilarating.The activities were well-organized and full of excitement.A must-visit for thrill seekers!",
    stars:"rating-5.svg",name:"Kumar",profession:"Sales Manager",},
    {text:"Dew Drops was the perfect romantic retreat. Jungle Safari and boating added adventure.Exceptional hospitality made our stay special.We'll cherish our time here!",
    stars:"rating-5.svg",name:"Ruhi",profession:"Doctor"},
]

export const destinationImages=[
    {label:"Kali River",imageUrl:"kali-river.png"},
    {label:"Sykes Point",imageUrl:"sykes-point.png"},
    {label:"Syntheri Rocks",imageUrl:"synteric-rock.png"},
    // {label:"Moulangi Eco Park",imageUrl:""},
    {label:"Jungle Safari",imageUrl:"jungle-safari.jpg"},
    {label:"Supa Dam",imageUrl:"supadam.jpeg"},
]

export const packagesData=[
    {imageUrl:"package-1.png",ratingImage:"rating.svg",label:"jungle-vibes",label2:"Jungle Stay",desc:"Nestled in the heart of the forest, Jungle Stay offers an immersive experience in nature. Wake up to the sounds of chirping birds, surrounded by lush greenery.",cost:1300},
    {imageUrl:"package-2.png",ratingImage:"rating-4.svg",label:"river-point",label2:"River View Stay",desc:"Located at the serene banks of the river, River View provides a peaceful escape from the hustle and bustle of city life.",cost:1500},
    {imageUrl:"package-3.png",ratingImage:"rating-4.svg",label:"river-edge",label2:"River Side Stay",desc:"River Side combines relaxation with adventure, offering guests the chance to engage in thrilling water sports. .",cost:1500},
    {imageUrl:"package-1.png",ratingImage:"rating-5.svg",label:"kings",label2:"Luxury Pool View Stay",desc:"Step into a world of luxury with the Luxury Pool View Stay package. Featuring spacious accommodations and top-notch services, this package promises a regal experience.",cost:1800},
    {imageUrl:"package-2.png",ratingImage:"rating-5.svg",label:"dew-drops",label2:"Luxury Nature View Stay",desc:"Luxury Nature View Stay offers a refreshing retreat with its tranquil surroundings and lush gardens. Perfect for relaxation and rejuvenation, yoga sessions, nature walks",cost:2000},
    {imageUrl:"package-2.png",ratingImage:"rating-5.svg",label:"manomaya-cottages",label2:"A Frame Luxury Cottages",desc:"A Frame Luxury Cottages provide an unparalleled luxury experience with elegantly designed spaces and personalized services.",cost:2500},
]

export const activities=[
    
        // {imageUrl:"nature.svg",label:"Nature Walk"},
        // {imageUrl:"fire.svg",label:"Campfire"},
        // {imageUrl:"boating.svg",label:"River Zorbing"},
        // {imageUrl:"boating.svg",label:"River Zorbing"},
        {imageUrl:"rafting.png",label:"Kayaking"},
        
        
        {imageUrl:"2boating24.jpg",label:"Rafting"},
        {imageUrl:"2ziplining24.jpg",label:"Zip Lining"},
        // {imageUrl:"zorbing.png",label:"Zorbing"},
        {imageUrl:"2zorbing-2.JPG",label:"Zorbing"},
        {imageUrl:"2zorbing-1.JPG",label:"Zorbing"},
        {imageUrl:"2kayaking-1.JPG",label:"Kayaking"},
        {imageUrl:"2ziplining-1.JPG",label:"Zip Lining"},
        // {imageUrl:"boating-1.jpg",label:"Boating"},
        {imageUrl:"2riverswimming.png",label:"River Swimming"},
        // {imageUrl:"rafting-12.JPG",label:"Boating"},
        // {imageUrl:"kayaking24.png",label:"Kayaking"},
        
        //ai
        // {imageUrl:"kayaking.jpg",label:"Kayaking"},

]

export const jungleVibes=[
    {label:"",imageUrl:"junglevibe1.JPG"},
    {label:"",imageUrl:"junglevibe2.JPG"},
    {label:"",imageUrl:"junglevibe3.JPG"},
    {label:"",imageUrl:"junglevibe4.JPG"},
    {label:"",imageUrl:"junglevibe5.JPG"},
    // {label:"",imageUrl:"junglevibe6.JPG"},
    {label:"",imageUrl:"junglevibe7.JPG"},
    {label:"",imageUrl:"junglevibe8.JPG"},
    {label:"",imageUrl:"junglevibe9.JPG"},
    {label:"",imageUrl:"junglevibe10.JPG"},
    {label:"",imageUrl:"junglevibe11.JPG"},
    {label:"",imageUrl:"junglevibe12.JPG"},
]

export const riverPoint=[
    {label:"riverpoint",imageUrl:"riverpoint1.JPG"},
    {label:"riverpoint",imageUrl:"riverpoint2.JPG"},
    {label:"riverpoint",imageUrl:"riverpoint3.JPG"},
    {label:"riverpoint",imageUrl:"riverpoint4.JPG"},
    {label:"riverpoint",imageUrl:"riverpoint5.JPG"},
    {label:"riverpoint",imageUrl:"riverpoint6.JPG"},
    {label:"riverpoint",imageUrl:"riverpoint7.JPG"},
    {label:"riverpoint",imageUrl:"riverpoint8.JPG"},
    {label:"riverpoint",imageUrl:"riverpoint9.JPG"},
    {label:"riverpoint",imageUrl:"riverpoint10.JPG"},
    {label:"riverpoint",imageUrl:"riverpoint11.JPG"},
    {label:"riverpoint",imageUrl:"riverpoint12.JPG"},
    {label:"riverpoint",imageUrl:"riverpoint13.JPG"},
    {label:"riverpoint",imageUrl:"riverpoint14.JPG"},
    {label:"riverpoint",imageUrl:"riverpoint15.JPG"},
    {label:"riverpoint",imageUrl:"riverpoint16.JPG"},
    {label:"riverpoint",imageUrl:"riverpoint17.JPG"},
    {label:"riverpoint",imageUrl:"riverpoint18.JPG"},
]

export const dewDrops=[
    {label:"dewdrops",imageUrl:"ddewdrops1.jpg"},
    // {label:"dewdrops",imageUrl:"ddewdrops2.jpg"},
    {label:"dewdrops",imageUrl:"ddewdrops3.jpg"},
    {label:"dewdrops",imageUrl:"ddewdrops4.jpg"},
    {label:"dewdrops",imageUrl:"ddewdrops5.jpg"},
    {label:"dewdrops",imageUrl:"ddewdrops6.jpg"},
    {label:"dewdrops",imageUrl:"ddewdrops7.jpg"},
    {label:"dewdrops",imageUrl:"ddewdrops8.jpg"},
    {label:"dewdrops",imageUrl:"ddewdrops9.jpg"},
    {label:"dewdrops",imageUrl:"ddewdrops10.jpg"},
    {label:"dewdrops",imageUrl:"ddewdrops11.jpg"},
    {label:"dewdrops",imageUrl:"ddewdrops12.jpg"},
    {label:"dewdrops",imageUrl:"ddewdrops13.jpg"},
    {label:"dewdrops",imageUrl:"ddewdrops14.jpg"},
    {label:"dewdrops",imageUrl:"ddewdrops15.jpg"},
    {label:"dewdrops",imageUrl:"ddewdrops16.jpg"},
    {label:"dewdrops",imageUrl:"ddewdrops17.jpg"},
    {label:"dewdrops",imageUrl:"ddewdrops18.jpg"},
]

export const kings=[
    {label:"kings1",imageUrl:"./images/kings/kings1.jpg"},
    {label:"kings2",imageUrl:"./images/kings/kings2.jpg"},
    {label:"kings3",imageUrl:"./images/kings/kings3.jpg"},
    {label:"kings4-new",imageUrl:"./images/kings/kings4-new.jpg"},
    {label:"kings5",imageUrl:"./images/kings/kings5.jpg"},
    {label:"kings6",imageUrl:"./images/kings/kings6.jpg"},
    {label:"kings7-new",imageUrl:"./images/kings/kings7-new.jpg"},
    {label:"kings8",imageUrl:"./images/kings/kings8.jpg"},
    {label:"kings9",imageUrl:"./images/kings/kings9.jpg"},
    {label:"kings10",imageUrl:"./images/kings/kings10.jpg"},
    {label:"kings11",imageUrl:"./images/kings/kings11.jpg"},
    {label:"kings12",imageUrl:"./images/kings/kings12.jpg"},
    {label:"kings13",imageUrl:"./images/kings/kings13.jpg"},
    {label:"kings14",imageUrl:"./images/kings/kings14.jpg"},
    {label:"kings15",imageUrl:"./images/kings/kings15.jpg"},
    {label:"kings16",imageUrl:"./images/kings/kings16.jpg"},
    // {label:"kings17",imageUrl:"./images/kings/kings.jpg"},
    {label:"kings18",imageUrl:"./images/kings/kings18.jpg"},
    {label:"kings19",imageUrl:"./images/kings/kings19.jpg"},
    {label:"kings20",imageUrl:"./images/kings/kings20.jpg"},
    {label:"kings21",imageUrl:"./images/kings/kings21.jpg"},
    {label:"kings22",imageUrl:"./images/kings/kings7-new.jpg"},
    {label:"kings23",imageUrl:"./images/kings/kings23.jpg"},
    {label:"kings24",imageUrl:"./images/kings/kings24.jpg"},
    {label:"kings25",imageUrl:"./images/kings/kings25.jpg"},
    {label:"kings26",imageUrl:"./images/kings/kings26.jpg"},
    {label:"kings27",imageUrl:"./images/kings/kings27.jpg"},
    {label:"kings28",imageUrl:"./images/kings/kings28.jpg"},
    {label:"kings29",imageUrl:"./images/kings/kings29.jpg"},
]
export const manomayaCottages=[
    {label:"manomayacottages1",imageUrl:"./images/manomaya/manomayacottages1.JPG"},
    {label:"",imageUrl:"./images/manomaya/manomayacottages2.JPG"},
     {label:"",imageUrl:"./images/manomaya/manomayacottages3.JPG"},
    {label:"",imageUrl:"./images/manomaya/manomayacottages4.JPG"},
    {label:"",imageUrl:"./images/manomaya/manomayacottages5.JPG"},
     {label:"",imageUrl:"./images/manomaya/manomayacottages6.JPG"},
     {label:"",imageUrl:"./images/manomaya/manomayacottages7.JPG"},
     {label:"",imageUrl:"./images/manomaya/manomayacottages8.JPG"},
     {label:"",imageUrl:"./images/manomaya/manomayacottages9.JPG"},
     {label:"",imageUrl:"./images/manomaya/manomayacottages10.JPG"},
     {label:"",imageUrl:"./images/manomaya/manomayacottages11.JPG"},
     {label:"",imageUrl:"./images/manomaya/manomayacottages12.JPG"},
     {label:"",imageUrl:"./images/manomaya/manomayacottages13.JPG"},
     {label:"",imageUrl:"./images/manomaya/manomayacottages14.JPG"},
     {label:"",imageUrl:"./images/manomaya/manomayacottages15.JPG"},
     {label:"",imageUrl:"./images/manomaya/manomayacottages16.JPG"},
     {label:"",imageUrl:"./images/manomaya/manomayacottages17.JPG"},
     {label:"",imageUrl:"./images/manomaya/manomayacottages18.JPG"},
     {label:"",imageUrl:"./images/manomaya/manomayacottages19.JPG"},
     {label:"",imageUrl:"./images/manomaya/manomayacottages20.JPG"},
     {label:"",imageUrl:"./images/manomaya/manomayacottages22.JPG"},
     {label:"",imageUrl:"./images/manomaya/manomayacottages23.JPG"},
     {label:"24",imageUrl:"./images/manomaya/mmanomayacottages24.JPG"},
]

export const riverEdge=[
    
    {label:"riveredge2",imageUrl:"./images/river-edge/riveredge2.JPG"},
    {label:"riveredge3",imageUrl:"./images/river-edge/riveredge3.JPG"},
    {label:"riveredge",imageUrl:"./images/river-edge/riveredge4.JPG"},
    
    {label:"riveredge",imageUrl:"./images/river-edge/riveredge9.JPG"},
    {label:"riveredge",imageUrl:"./images/river-edge/riveredge10.JPG"},
    {label:"riveredge",imageUrl:"./images/river-edge/riveredge11.JPG"},
    {label:"riveredge1",imageUrl:"./images/river-edge/riveredge1.JPG"},
    {label:"riveredge",imageUrl:"./images/river-edge/riveredge5.JPG"},
    {label:"riveredge",imageUrl:"./images/river-edge/riveredge6.JPG"},
    {label:"riveredge",imageUrl:"./images/river-edge/riveredge7.JPG"},
    {label:"riveredge",imageUrl:"./images/river-edge/riveredge8.JPG"},
    {label:"riveredge",imageUrl:"./images/river-edge/riveredge12.JPG"},
    {label:"riveredge",imageUrl:"./images/river-edge/riveredge13.JPG"},
]

export const contactOwner={
    "number":"918277127364",
    "email":"dandelitripzone@gmail.com",
    "number2":"918884695715",
}

export const packageDetails=[
    {path:"",label:"Jungle Stay",desc:"Immerse yourself in the heart of nature with the Jungle Stay package. This unique offering provides a perfect blend of adventure and tranquility. Wake up to the melodious sounds of chirping birds and rustling leaves as you're surrounded by lush greenery. Explore the vibrant wildlife and participate in guided nature walks that showcase the rich biodiversity of Dandeli. Jungle Stay is ideal for nature enthusiasts and those seeking a serene escape from the hustle and bustle of daily life.",benefits:"",note:""},
    {path:"",label:"River View Stay",desc:"Experience serenity at its best with the River View Stay package. Situated on the tranquil banks of the river, this stay provides breathtaking views and a peaceful atmosphere. Enjoy your morning coffee while watching the gentle flow of the river from your private balcony. With easy access to water activities like rafting and kayaking, River View Stay is perfect for those who appreciate both relaxation and adventure. It's a haven for anyone looking to unwind and reconnect with nature.",
        benefits:"",note:""},
    {path:"",label:"River Side Stay",desc:"For thrill-seekers and adventure lovers, River Side Stay offers an unparalleled experience. Nestled along the riverside, this stay combines relaxation with exhilarating water sports. Participate in thrilling activities such as rafting, zip lining, and river swimming. After an action-packed day, relax in comfortable accommodations that offer stunning views of the river. River Side Stay promises an unforgettable adventure-filled getaway, making it a top choice for outdoor enthusiasts.",
        benefits:"",note:""},
    {path:"",label:"Luxury Pool View Stay",desc:"Indulge in luxury with the Luxury Pool View Stay package, offering a regal experience in the wild. With spacious accommodations and top-notch amenities, you'll feel like royalty. Enjoy stunning pool views from your room and take a refreshing dip in the luxurious pool. The Luxury Pool View Stay also includes access to exclusive services that ensure your stay is both comfortable and memorable. It's an ideal choice for those looking to enjoy the finer things while being surrounded by the beauty of nature.",
        benefits:"",note:""},
    {path:"",label:"Luxury Nature View Stay",desc:"Escape to tranquility with the Luxury Nature View Stay package, a perfect retreat for relaxation and rejuvenation. Nestled amidst lush gardens, this stay offers a refreshing atmosphere and stunning nature views. Participate in yoga sessions or take a leisurely nature walk to rejuvenate your mind and body. The serene surroundings and luxurious accommodations make Luxury Nature View Stay a perfect choice for those seeking a peaceful and rejuvenating escape.",
        benefits:"",note:""},
    {path:"",label:"A Frame Luxury Cottage",desc:"Experience unparalleled luxury with the A-Frame Luxury Cottages package. These elegantly designed A-frame cottages offer personalized services and an exclusive stay experience. Enjoy the perfect blend of comfort and elegance, with stunning interiors and modern amenities. A-Frame Luxury Cottages provide a unique and luxurious experience, making it an ideal choice for those looking for a lavish getaway in the lap of nature.",
    benefits:"",note:""},

]

export const sendMessage=()=>{
    const phoneNumber = '918277127364'; 
   const message = `Hi there! 🌟

We’re excited about planning a trip to Dandeli and would love some guidance. Could you please provide details about your packages and activities?

Looking forward to your response! 😊`;


   const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

   window.open(whatsappUrl, '_blank');
}