import { useState,useEffect } from "react";
import { reviews } from "../utils/constants";
import { RxCross2 } from "react-icons/rx";

const Review=()=>{
    const [review,setReview]=useState({"review": "",
        rating: 5,
        name: ""});
    const [showReviewModal,setShowReviewModal]=useState(false);
    const [fetchedReviews,setFetchedReviews]=useState([]);

    const handleChange=(e)=>{
        const {name,value}=e.target;
        const updatedReview={...review,[name]:value}
        setReview(updatedReview);
    }

    const handleSubmit=async(e)=>{
        e.preventDefault();
        const options={
            method:"POST",
            headers:{
                "content-type":"application/json",
            },
            body:JSON.stringify(review),
        }
        try{
            await fetch("https://dandeli-backend.vercel.app/reviews",options);
            setReview({"review": "",
                "rating": 5,
                "name": ""});
            setShowReviewModal(false);
            getreviews();// refresh new review data

        }
        catch(error){
            console.log(error);
        } 

    }
    const closeForm=()=>{
        setReview({review: "",
            rating: 5,
            name: ""});
        setShowReviewModal(false); 
    }
    const getreviews=async()=>{
        try{
            const response= await fetch('https://dandeli-backend.vercel.app/reviews');
            if(response.ok){
                const result=await response.json();
                console.log(result);
                setFetchedReviews(result);
            }
        }
        catch(error){
            console.log(error);
        }
    }

    // const postreviews=async()=>{
       
    // }
  
    useEffect(()=>{
        if(fetchedReviews.length===0){
            getreviews();
        }
     
    })

    const mapy=(i)=>{
        if(i===1){
            return "rating.svg";
        }
        else if(i===2){
            return "rating-2.svg";
        }
        else if(i===3){
            return "rating-3.svg";
        }
        else if(i===4){
            return "rating-4.svg";
        }
        else{
            return "rating-5.svg";
        }
    }

    const minifyName=(name)=>{
        let temp=name.split(" ");
        temp = temp.map(word => 
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
        return (temp.slice(0,3)).join(" "); //minimum 3 names are allowed 
    }

    return(
        <div className="sm:h-[96vh] sm:pl-10 sm:pt-[6vh]">
            <div className=" justify-between hidden sm:flex items-center mb-4">
                <div className="flex flex-col">
                    <h1 className="abyssinica-sil-regular text-3xl ">Guest Reviews</h1>
                    <hr className="border-[#8DCD80] border-[1.6px] ml-[2px] mr-[14px]" />
                </div>
                <div className="sm:flex flex-row mr-[3%] space-x-8 hidden">
                    <img src ="left-arrow.svg" alt="left-arrow" className="cursor-pointer" onClick={()=>{document.querySelector(".review-container").scrollBy({left:-400,behavior:"smooth"})}} />
                    <img src="right-arrow.svg"  alt="right-arrow" className="cursor-pointer" onClick={()=>{document.querySelector(".review-container").scrollBy({left:400,behavior:"smooth"})}}/>
                </div>
            </div>

            <h1 className="abyssinica-sil-regular text-3xl mb-3 sm:hidden block pl-5 sm:pl-0">Guest Reviews</h1>
            <hr className="border-[#8DCD80] border-[2px] mb-4 w-[240px] sm:hidden block ml-5 " />

            <p className="text-[#6A6E71] sm:mb-6 pl-5 sm:pl-0">At Dandeli Resort, we take immense pride in providing a memorable and enriching experience for our guests. Here's what some of our recent visitors have to say about their stay with us:</p>
            <div className="flex flex-col sm:flex-row items-center overflow-y-auto sm:w-[94vw] text-[#6A6E71] review-container overflow-x-hidden">
                {fetchedReviews.length>0 ? fetchedReviews.map((review,idx)=>
                    <div className="flex flex-col p-7 color-[#767E86] bg-[#F5F7F5] sm:w-[40vw] sm:h-[261px] sm:mr-[3%] mt-[2%] flex-shrink-0 m-3 sm:m-0 pl-7 overflow-hidden shadow-lg sm:border-2 sm:border-[#878584] sm:rounded-[3px]" key={idx}>
                        <p className="">{review.review}</p>
                        <div className="mt-auto">
                            <img src={mapy(review.rating)} alt="rating" className="h-[30px] w-[94px]" />
                            <h2 className="text-xl"
                            style={{fontFamily: 'Kantibeh',letterSpacing:'1px'}}>{minifyName(review.name)}</h2>
                        </div>
                        
                        {/* <p>{review.profession}</p> */}
                    </div>
                ):
                reviews.map((review,idx)=>
                    <div className="flex flex-col p-7 color-[#767E86] bg-[#F5F7F5] sm:w-[40vw] sm:mr-[3%] mt-[2%] flex-shrink-0 m-3 sm:m-0 pl-7 overflow-x-hidden" key={idx}>
                        <p className="">{review.review}</p>
                        <img src={mapy(review.rating)} alt="rating" className="h-[70px] w-[94px]" />
                        <h2 className="text-xl"
                         style={{fontFamily: 'Kantibeh',letterSpacing:'1px'}}>{minifyName(review.name)}</h2>
                    </div>
                )}
            </div>
            <div className="sm:mt-[6vh] my-[3vh] sm:my-0 flex justify-center items-center">
                <button className="px-3 py-2 bg-[#1B9B00] text-white rounded-md cursor-pointer" style={{}} onClick={()=>{setShowReviewModal(true)}}>Share Your Experience</button>
            </div>
            
            {/* <div className="sm:flex flex-row ml-[42%] space-x-8 mt-[3%] hidden">
                <img src ="left-arrow.svg" alt="left-arrow" className="cursor-pointer" onClick={()=>{document.querySelector(".review-container").scrollBy({left:-400,behavior:"smooth"})}} />
                <img src="right-arrow.svg"  alt="right-arrow" className="cursor-pointer" onClick={()=>{document.querySelector(".review-container").scrollBy({left:400,behavior:"smooth"})}}/>
            </div> */}
            {
                showReviewModal &&(
                    <div className="fixed top-0 left-0 w-[100vw] h-[100vh] bg-[white] flex justify-center items-center" 
                    style={{backgroundColor:'rgba(0,0,1,0.4)',zIndex:"10000"}}>
                    <form onSubmit={handleSubmit} 
                        className="rounded-[9px] max-w-md mx-auto p-6 bg-[#000000] w-[87vw] sm:w-[40vw] h-[65vh] ">
                        <div className="flex items-center mb-3">
                            <h2 className="text-xl font-semibold text-white">Leave a Review</h2>
                            <RxCross2 className="ml-auto mr-[10px] cursor-pointer" onClick={()=>{closeForm()}} style={{height:"23px",width:"23px",color:"white"}} />
                        </div>
                        
                        
                        <div className="mb-4">
                            <label className="block mb-1 text-white" htmlFor="name">Name </label>
                            <input
                            id="name"
                            name="name"
                            type="text"
                            value={review.name}
                            onChange={handleChange}
                            className="w-full border border-gray-300 p-2 rounded"
                            placeholder="Your name"
                            required
                            />
                        </div>
                        {/* <div className="mb-4">
                            <label className="block mb-1 text-white" htmlFor="profession">Occupation</label>
                            <input
                                id="profession"
                                name="profession"
                                type="text"
                                value={review.profession}
                                onChange={handleChange}
                                className="w-full border border-gray-300 p-2 rounded"
                                placeholder="Your occupation"
                                required
                                />
                        </div> */}
                        <div className="mb-4">
                            <label className="block mb-1 text-white" htmlFor="stars">Star Rating
                                 {/* <span style={{color:"#e84427"}}>{" "}*</span>  */}
                            </label>
                            <select
                            id="stars"
                            name="rating"
                            value={review.stars}
                            onChange={handleChange}
                            className="w-full border border-gray-300 p-2 rounded"
                            required
                            >
                                <option value="" disabled>
                                    Select a rating
                                </option>
                                <option value="1">1 Star</option>
                                <option value="2">2 Stars</option>
                                <option value="3">3 Stars</option>
                                <option value="4">4 Stars</option>
                                <option value="5">5 Stars</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block mb-1 text-white" htmlFor="text">Review
                                {/* <span style={{color:"#e84427"}}>{" "}*</span>  */}
                             {" "}(max 500 characters) </label>
                            <textarea
                            id="text"
                            name="review"
                            value={review.text}
                            onChange={handleChange}
                            rows="4"
                            maxLength="500" 
                            className="w-full border border-gray-300 p-2 rounded"
                            placeholder="Write your review here..."
                            required
                            />
                        </div>
                        
                        <div className="flex justify-center items-center">
                        <button
                            type="submit"
                            className="bg-[#1B9B00] text-white px-4 py-2 rounded "
                        >
                            Submit Review
                        </button>
                        </div>
                        
                        </form>
                        </div>
                )
            }
            
        </div>
    )
}

export default Review;