import About from "./about";
import Activities from "./activities";
import Booknow from "./booknow";
import Destination from "./destinations";
import Home from "./home";
import Packages from "./packages";
import Review from "./review";

const AllPages=()=>{
    return(
        <div className="App overflow-x-hidden w-[100vw]">
        {/* <Navbar/> */}
            <Home/>
            <Destination/>
            <Packages/>
            <Activities/>
            {/* <EmptySpace/> */}
            <About/>
            <Booknow/>
            <Review/>
            {/* <Footer/> */}
        </div>
    )
}

export default AllPages;