const About=()=>{

    return(
       <div className=" sm:pl-15 pl-5 pt-[5%] mb-[3vh]" id="about">
            <div className="flex flex-col">
                {/* time pass */}
            
                <h1 className="abyssinica-sil-regular text-3xl sm:text-3xl mb-3 font-700">About Us</h1>
                <hr className="border-[#8DCD80] border-[1.7px] mb-4 ml-[2px] mr-[12px] w-[102px]" />
                {/* <p className="mb-3">Start your hassle-free Dandeli adventure. Explore resorts, homestays, and adventures effortlessly. Your memorable Dandeli experience begins here!</p> */}
                <div className="flex flex-col sm:flex-row sm:justify-center p-6 sm:p-0">
                    <img src="aboutus-2.JPG" alt="profile" className="sm:h-[73vh] mr-10 sm:mb-0 mb-2"/>
                    <div className="flex flex-col sm:pl-6" style={{fontFamily:"italic"}}>
                        {/* <h1 className="font-normal sm:text-3xl text-[54px] leading-57 sm:mb-[3vh]" style={{fontFamily:"italic",fontSize:"28px"}}>Beautiful Dandeli </h1>
                        <h1 className="font-normal text-3xl text-[54px] leading-57 sm:mb-[3vh]" style={{fontFamily:"italic",fontSize:"27px"}}>Let’s Explore</h1> */}
                       <p className="text-4xl mb-4 font-bold ">Discover Dandeli:  <br/> Nature's Adventure  Playground</p>
                        <p className="sm:w-[34vw] mb-[2vh] text-xl sm:text-justify text-left tracking-normal">
                           
                            Escape to Dandeli, nestled in the lush Western Ghats, where adventure meets serenity. Conquer the mighty Kali River with thrilling white-water rafting. Explore the diverse wildlife of Dandeli Wildlife Sanctuary, home to tigers and over 200 bird species. Traverse scenic trails to uncover hidden waterfalls and breathtaking vistas.
                            Unwind in charming jungle resorts, savor local North Karnataka cuisine, and experience vibrant tribal cultures. As night falls, the starlit skies invite you for magical stargazing and campfire tales. Dandeli isn't just a destination; it's an unforgettable journey into nature's embrace.
                           <p className="font-bold mt-[4vh] text-2xl"> Embark on Your Adventure Today!</p>
                            
                                </p>
                        {/* <p className="text-green-600 flex flex-row gap-3 items-center cursor-pointer">Read More <FaArrowRightLong className="mt-1" /></p> */}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default About;