import { packagesData,jungleVibes, manomayaCottages, dewDrops, kings, riverPoint, riverEdge, sendMessage } from "../utils/constants";
//import { IoIosArrowBack,IoIosArrowForward } from "react-icons/io";
import {useEffect} from 'react';

const Packages=()=>{
    // const scrollContainerRef = useRef(null);
   // const [packages,setPackages]=useState([jungleVibes,riverEdge,manomayaCottages,kings,dewDrops,riverPoint]);
    const packages=[jungleVibes,riverPoint,riverEdge,kings,dewDrops,manomayaCottages,]

    // const scrollLeft = () => {
    //   if (scrollContainerRef.current) {
    //     scrollContainerRef.current.scrollBy({
    //       left: -200, 
    //       behavior: 'smooth',
    //     });
    //   }
    // };
  
    // const scrollRight = () => {
    //   if (scrollContainerRef.current) {
    //     scrollContainerRef.current.scrollBy({
    //       left: 200, 
    //       behavior: 'smooth',
    //     });
    //   }
    // };

    // const scrollAllContainers = () => {
     
    //   const containers = document.querySelectorAll(".ippbd");
    //   containers.forEach(container => {
    //     container.scrollBy({
    //       left: 100,
    //       behavior: "smooth",
    //     });
    //   });
    // };
    useEffect(() => {
      const containers = document.querySelectorAll(".ippbd");
      const isMobile = window.innerWidth <= 768;
      let scrollStep;
      let scrollInterval;
      if(isMobile){
         scrollStep = 10; 
         scrollInterval = 70; 

      }
      else{
        scrollStep = 380; 
         scrollInterval = 2000; 
      }
       // 2sec -2000 milliseconds //380 with 2000 is also good full image // 180 1000 also good half image
      
      // Function to scroll each container
      const scrollAllContainers = () => {
        containers.forEach(container => {
          const maxScroll = container.scrollWidth - container.clientWidth;
          const currentScroll = container.scrollLeft;
          console.log(scrollStep);
          if (currentScroll + container.clientWidth >= maxScroll) {
            // Scroll back to the start when reaching the end
            container.scrollTo({ left: 0, behavior: `${ isMobile ?'auto':'smooth'}` });
          } else {
            // Scroll by the specified step amount
            
            container.scrollBy({ left: scrollStep, behavior: "smooth" });
          }
        });
      };
  
      // Set interval for continuous scrolling
      const intervalId = setInterval(scrollAllContainers, scrollInterval);
  
      // Cleanup the interval on component unmount
      return () => clearInterval(intervalId);
    }, []);

    return(
          <div className="sm:min-h-[109vh] sm:pl-10 pl-5 pt-[10vh]" id="resorts">
            <div className="flex flex-row w-full">
                <div className="destination">
                  <div className="inline-block"> 
                    <h1 className="abyssinica-sil-regular text-xl sm:text-3xl mb-3">Exclusive Dandeli Resort Packages</h1>
                    <hr className="border-[#8DCD80] w-[92%] border-[1.8px] mb-4 ml-[2px]" />
                  </div>
                </div>
                <div className="sm:flex flex-row space-x-8 ml-auto mr-[60px] hidden">
                    <img src ="left-arrow.svg" alt="left-arrow" className="cursor-pointer  h-[40px]" onClick={()=>{document.querySelector(".package-container").scrollBy({left:-400,behavior:"smooth"})}} />
                    <img src="right-arrow.svg"  alt="right-arrow" className="cursor-pointer  h-[40px]" onClick={()=>{document.querySelector(".package-container").scrollBy({left:400,behavior:"smooth"})}} />
                </div>
            </div>
           
            <p className="text-[#6A6E71] mb-5 sm:max-w-[60vw]">Start your hassle-free Dandeli adventure. Explore resorts, homestays, and adventures effortlessly. Your memorable Dandeli experience begins here!</p>
            <div className="flex flex-col sm:flex-row w-full package-container overflow-x-auto">
                {packagesData.map((packag,idx)=>
                <a className="w-[90vw] sm:w-[23vw] flex-shrink-0 mr-5 p-4 border-[2px] border-[#1BA100] rounded-[8px] mb-4 shadow-sm sm:shadow-lg sm:drop-shadow-sm gap-4 sm:gap-0" key={idx} href={`/${packag.label}`}>
                    {<div className="flex flex-row w-full overflow-x-auto ippbd jungle-images-container">
                        {/* <IoIosArrowBack
                            className="absolute top-1/2 left-10 transform -translate-y-1/2 text-white cursor-pointer sm:block hidden "
                            onClick={scrollLeft}
                            // styles={{color:"red",fontSize:"20px"}}
                        /> */}
                        { packages[idx].slice(1,5).map((pkg,idx)=>
                        <div className="relative flex flex-row items-center flex-shrink-0 sm:w-[23vw] sm:h-[70%] h-[285px] w-[85vw]" key={idx}>
                            {/* <IoIosArrowBack className=" left-3 text-white cursor-pointer" /> */}
                            <img src={pkg?.imageUrl} alt={pkg.label} className="w-full h-[280px] object-cover"/>
                            {/* <IoIosArrowForward className="absolute right-3 text-white cursor-pointer" /> */}
                        </div>) }
                        
                        {/* <IoIosArrowForward
                            className="absolute top-1/2 right-10 transform -translate-y-1/2 text-white cursor-pointer sm:block hidden"
                            onClick={scrollRight}
                            // styles={{color:"red",fontSize:"20px"}}
                        /> */}
                        </div>}
                   
                    <h1 className="font-500 text-2xl font-semibold sm:mt-2 mt-1 m-2">{packag.label2}</h1>
                    <img src={`${packag.ratingImage}`} alt="rating" className="h-[60px] w-[130px]  m-2"/>
                    <p className="color-[#666666] sm:h-[110px]  m-2">{packag.desc}</p>
                    <div className="flex flex-row items-center sm:mt-auto mt-2  m-2">
                        <div className="flex flex-row items-center">From <p className="ml-3 font-bold">&#8377;{packag.cost}</p></div>
                        {/* <a href={`https://wa.me/'${contactOwner.number}'/?text=Hello,+we+are+planning+a+trip+to+dandeli`} target="_blank_" className="ml-auto"> */}
                        <button className=" border border-[#CDD3DB] rounded-[23px] px-4 py-1 bg-green-500 text-white hover:border-none ml-auto" onClick={()=>{sendMessage()}}>Book Now
                        
                        </button>
                        {/* </a> */}
                    </div>
                </a>)}
            </div>

        </div>
    )
}

export default Packages;