import '../index.css';
import { sendMessage } from '../utils/constants';

const Home=()=>{

    return(
        <div className="home h-[100vh] text-center flex flex-col pt-[20vh] text-white p-5" id="home">
            <h1 className="home-header z-2">NATURE & ADVENTURES<br className='hidden sm:block' /> AT ITS BEST!</h1>
            <p className='sm:w-[50vw] mx-auto mt-[4vh]'>Nature is at its bountiful best at Dandeli (North Karnataka) making it an ideal holiday destination. The unparalleled scenic beauty of this destination located on the banks of the Kali River is complemented by the exotic wildlife that resides in its tropical forests.</p>
            {/* <a href={`https://wa.me/'${contactOwner.number}'/?text=Hello,+we+are+planning+a+trip+to+dandeli`} target="_blank_"> <button className="bg-[#1B9B00] text-white rounded-[24px] pl-10 pr-7 py-2 flex flex-row items-center mx-auto mt-[4vh]">
                Book Now
                <img src="animated-nudge.svg" alt="" className="ml-2"/>
            </button></a> */}
            <button className="bg-[#1B9B00] text-white rounded-[24px] pl-10 pr-7 py-2 flex flex-row items-center mx-auto mt-[4vh]" onClick={()=>{sendMessage()}}>
                Book Now
                <img src="animated-nudge.svg" alt="" className="ml-2"/>
            </button>
        </div>
    )
}

export default Home;